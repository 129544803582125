import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  // 本関数は
  //  app/views/xxxx/xxxx.html.erb
  // にて使われます。
  //-------------------------------------------------------------
  // data_areaのドラッグ＆ドロップ制御
  //   usage:
  //     1.呼びたいページのどこかに以下を追加
  //      <div data-controller='draganddrop'></div>
  //     2.ドラッグしたい要素に以下を追加
  //      class:'drag_item' ,draggable:'true'
  //
  //  参考https://blog.ver001.com/javascript-dragdrop-sort/#toc7
  //-------------------------------------------------------------
  connect(){
    document.querySelectorAll('.drag_item').forEach (elm => {
      // ドラッグを開始した時
      elm.ondragstart = function (event) {
        event.dataTransfer.setData('text/plain', event.target.id);
      };
      // ドラッグした要素が別の要素の上に重なった時
      elm.ondragover = function (event) {
        event.preventDefault();
        let rect = this.getBoundingClientRect(); // カーソル位置(Y座標)を取得
        if ((event.clientY - rect.top) < (this.clientHeight / 2)) {
          //マウスカーソルの位置が要素の半分より上の時、上辺に線を表示
          this.style.borderTop = '2px solid #a9a9a9';
          this.style.borderBottom = '';
        } else {
          //マウスカーソルの位置が要素の半分より下の時、下辺に線を表示
          this.style.borderTop = '';
          this.style.borderBottom = '2px solid #a9a9a9';
        }
      };
      // ドラッグ中のマウスポインタが離れた時
      elm.ondragleave = function (event) {
        this.style.borderTop = '';
        this.style.borderBottom = '';
      };
      // ドロップした時
      elm.ondrop = function (event) {
        event.preventDefault();
        let id = event.dataTransfer.getData('text/plain');
        let elm_drag = document.getElementById(id);

        let rect = this.getBoundingClientRect(); // カーソル位置(Y座標)を取得
        if ((event.clientY - rect.top) < (this.clientHeight / 2)) {
          //マウスカーソル位置がドロップ先要素の半分より上の場合、ドロップ先の要素の前に挿入
          this.parentNode.insertBefore(elm_drag, this);
        } else {
          //マウスカーソル位置がドロップ先要素の半分より下の場合、ドロップ先の要素の次の要素の前に挿入
          this.parentNode.insertBefore(elm_drag, this.nextSibling);
        }
        this.style.borderTop = '';
        this.style.borderBottom = '';
      };
    });
  }
}