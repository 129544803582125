import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  // 本関数は
  //  app/views/shared/_menu.html.erb
  //  app/views/shared/_header.html.erb
  // にて使われます。

  //-------------------------------------------------------------
  // ウインドウのonloadに相当
  //   usage:
  //     data-controller='menu'
  //-------------------------------------------------------------
  connect () {
    var cookies = document.cookie.split(';');         // 全てのcookieを;で分割し配列に
    var menu    = document.getElementById('menu');    // メニューパネル開閉状態
    var article = document.getElementById('article'); // 
    // 一つ一つ取り出して(ex. "accordion_check5=false")
    for(var c of cookies){
      // さらに=で分割して配列にする(ex. cArray[0]="accordion_check5",cArray[1]="false")
      var cArray = c.split('=');
      // 配列０番目にオブジェクトID
      let element = document.getElementById(cArray[0].trim());
      // 要素が無い場合はスキップ
      if(element != null) {
        // スクロールの場合、Top座標をセット
        if ( element.id == 'menu_scroll' ){
          element.scrollTop = cArray[1];
        // メニューボタンのON/OFF切替
        } else if ( element.id == 'menu_open' ){
          if (cArray[1] == '') {
            this.open_menu_panel(false); // メニューパネルCLOSE＋アプリ画面最大化ON
          } else {
            this.open_menu_panel(true);  // メニューパネルOPEN＋アプリ画面最大化OFF
          }
          // その他はMenu項目チェックボックス
        } else {
          element.checked = this.to_boolean(cArray[1]); // 開閉状態をcheckboxに反映
        }
      }
    }
    // urlからハイライト
    this.highlighten(location.pathname);

    //  スマホ版ならアコーディオンメニューを閉じる
    if (window.matchMedia( "(max-width: 520px)" ).matches) {
      document.getElementById('menu').classList.remove('active');
    }
  }

  //-------------------------------------------------------------
  // メニューパネルの開閉制御
  // usage:  data-controller='menu', data-action='scroll->menu#panel'
  //-------------------------------------------------------------
  panel(event) {
    var clicked = '';
    // 選択時(active)を付与と除外を交互に行う
    // メニューパネル OPEN → CLOSE
    if (menu.classList.contains('active')) {
      this.open_menu_panel(false); // メニューパネルCLOSE＋アプリ画面最大化ON
    // メニューパネル CLOSE → OPEN
    } else {
      clicked = 'active';
      this.open_menu_panel(true);  // メニューパネルOPEN＋アプリ画面最大化OFF
    }
    // メニューパネル開閉状態をクッキーに保存
    document.cookie =  'menu_open=' + clicked + '; expires=Mon, 31 Aug 2220 00:00:00 GMT; Path=/';    
  }
  //-------------------------------------------------------------
  // メニューパネルの開閉
  //-------------------------------------------------------------
  open_menu_panel(is_open) {
    var menu    = document.getElementById('menu');
    var article = document.getElementById('article');
    // メニューパネルOPEN
    if(is_open) {
      menu.classList.add('active');           // メニューパネルOPEN
      article.classList.remove('maximized');  // アプリ画面の最大化OFF
    // メニューパネルCLOSE
    } else {
      menu.classList.remove('active');        // メニューパネルCLOSE
      article.classList.add('maximized');     // アプリ画面の最大化ON
    }
  }
  //-------------------------------------------------------------
  // ログアウト
  // usage:  data-controller='menu', data-action='click->menu#logout'
  // note:   ログアウト時にはメニューを折りたたむ状態にする
  //-------------------------------------------------------------
  logout(event) {
    document.cookie =  'menu_open=; expires=Mon, 31 Aug 2220 00:00:00 GMT; Path=/';    
  }

  //-------------------------------------------------------------
  // メニューのスクロール位置取得 , cookieに保持
  // usage:  data-controller='menu', data-action='scroll->menu#set_scroll_position'
  //-------------------------------------------------------------
  set_scroll_position(event){
    document.cookie =  'menu_scroll=' + this.element.scrollTop + '; expires=Mon, 31 Aug 2220 00:00:00 GMT; Path=/';
  }
  
  //-------------------------------------------------------------
  // メニュークリック(クリックmenuをハイライト)
  // usage:  data: {turbo_frame:  'main_pane',
  //                turbo_action: 'advance',
  //                controller:   'menu',
  //                action:       'click->menu#click_menu'}
  //-------------------------------------------------------------
  click_menu(event) {
    // 選択要素をハイライト
    this.highlighten(this.element.getAttribute('href'));

    //  スマホ版ならアコーディオンメニューを閉じる
    if (window.matchMedia( "(max-width: 520px)" ).matches) {
      document.getElementById('menu').classList.remove('active');
    }
  }

  //-------------------------------------------------------------
  //  メニュー親クリック(クリック情報をcookie保存)
  //  note:  アコーディオンchedkboxのクリック時､id , 開閉状態をcookie設定
  //  usage: data-controller='menu', data-action='change->menu#switch_parent'
  //-------------------------------------------------------------
  switch_parent(event) {
    // 要素のIDとチェック状態をcookieに保持
    document.cookie = this.element.id + '=' + this.element.checked + '; expires=Mon, 31 Aug 2220 00:00:00 GMT; Path=/'
  }

  //-------------------------------------------------------------
  //  PRIVATE関数
  //-------------------------------------------------------------

  //-------------------------------------------------------------
  // boolean変換
  //-------------------------------------------------------------
  to_boolean(value) { return value.toLowerCase() === 'true'; }

  //-------------------------------------------------------------
  // ハイライト関数
  //-------------------------------------------------------------
  highlighten(url) {
    // メニューリスト
    var menus = document.querySelectorAll('a.list-group-item.list-group-item-action');
    // 選択を初期化(classからactive排除)
    for(var i = 0; i < menus.length; ++i){
      // メニューを舐めてurlと一致したらハイライト
      if (url == menus[i].getAttribute('href')) {
        menus[i].classList.add('active');
      // ハイライトを消す
      } else {
        menus[i].classList.remove('active');
      }
    }
  }

}
