import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  //-------------------------------------------------------------
  // タブコンテンツ
  //   note:
  //     コンテンツ表示をタブで切り替える
  //   usage:
  //       <section class='tab_area' data-controller='tab'>
  // -----------------------------------------------------------
  //  《 タブコンテンツの作り方 》
  //    1.タブボタン・タブコンテンツ全体を<section class='tab_area'></section>で囲む
  //    2.タブのボタン部分全体を<nav class='tab_nav'></nav>で囲む
  //    3.各タブボタンにclass='tab_buttonを追加。デフォルトでアクティブにしたいタブにactiveクラスを追加
  //    4.各コンテンツにclass='tab_contentを追加。デフォルトでアクティブにしたいタブにactiveクラスを追加
  //   （例）
  //      <section class='tab_area' data-controller='tab'>
  //        <nav class='tab_nav'>
  //          <a class='tab_button active'>タブ１</a>
  //          <a class='tab_button'>タブ２</a>
  //        </nav>
  //      <div class='tab_content active'>
  //         <p>コンテンツ１</p>
  //      </div>
  //        <div class='tab_content'>
  //          <p>コンテンツ２/p>
  //        </div>
  //      </section>
  //-------------------------------------------------------------
  connect () {
    const tabsElems = document.querySelectorAll(".tab_area");

    if(tabsElems.length > 0) {
      for (let i = 0; i < tabsElems.length; i++) {
        let tab = tabsElems[i]
        let tabBtnElems = tab.querySelectorAll(".tab_button")
        let tabContentElems = tab.querySelectorAll(".tab_content")
        
        for (let i = 0; i < tabBtnElems.length; i++) {
          let tabBtn = tabBtnElems[i]
          let tabContent = tabContentElems[i]
          tabBtn.addEventListener("click", (e) => {
            e.preventDefault()
            for (let i = 0; i < tabBtnElems.length; i++) {
              tabBtnElems[i].classList.remove('active')
              tabContentElems[i].classList.remove('active')
            }
            tabBtn.classList.add('active')
            tabContent.classList.add('active')
          })
        }
      }
    }
  }
}