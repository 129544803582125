import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  // 本関数は
  //  app/views/rodauth/_login_form.html.erb
  //  app/views/rodauth/create_account.html.erb
  //  app/views/rodauth/verify_account.html.erb
  // にて使われます。

  static values = { id: Number }
  //-------------------------------------------------------------
  // パスワードの目のマーク制御
  //   note:
  //     クリックの都度、svgアイコンを差し替える
  //   usage:
  //     <%= form.password_field id: 'password2'
  //     <%= tag.span class: 'password_eye_icon',
  //       data: {
  //         controller: 'password',
  //         action:     'click->password#switch',
  //         'password-id-value': 2
  //       }
  //-------------------------------------------------------------
  switch() {
    var password_area = document.querySelector('#password'+String(this.idValue));   
    var svg = document.querySelectorAll('svg');
    if (password_area.type === "text") {
      svg[this.idValue-1].setAttribute('data-icon', 'eye');
      password_area.type = "password";
    } else {
      svg[this.idValue-1].setAttribute('data-icon', 'eye-slash');
      password_area.type = "text";
    }
  }
}