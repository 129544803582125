// PATCH: そのままだとstimulus-flatpickrをimport出来ないので、
// vi node_modules/stimulus-flatpickr/dist/index.m.js
// して先頭行を以下に変更する
//
// import { Controller } from "stimulus";
//                                ↓
// import { Controller } from "@hotwired/stimulus";
import Flatpickr from "stimulus-flatpickr"

// you can also import a translation file
import { Japanese } from 'flatpickr/dist/l10n/ja.js'

// import a theme (could be in your main CSS entry too...)
// import 'flatpickr/dist/themes/light.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize() {
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      locale: Japanese,
      dateFormat: "Y/m/d"
    }
  }
}