import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  // 本関数は
  //  app/views/xxxxx/index.html.erb
  // にて使われます。

  //-------------------------------------------------------------
  // 検索フォームのクリア
  // usage:  controller: 'index', action: 'click->index#reset'
  //-------------------------------------------------------------
  reset(event) {
    // 検索フォームのreset
    var sf = document.querySelectorAll(`[id$='_search']`)[0];
    sf.reset();
  }

  //-------------------------------------------------------------
  // 検索フォームの表示非表示切替
  // usage:  controller: 'index', action: 'click->index#show_search'
  //-------------------------------------------------------------
  show_search(event) {
    var sf = document.getElementsByClassName('search_form')[0];
    // 検索フォームの表示切替
    sf.style.display = (sf.style.display == 'none' || sf.style.display == '') ? 'block' : 'none';
  }

  //-------------------------------------------------------------
  // ウインドウのonloadに相当
  // usage:  controller: 'index'
  //-------------------------------------------------------------
  connect () {
    var table  = document.getElementById("table")           // テーブルの場合
    var cookies = document.cookie.split(";")                // 全てのcookieを;で分割し配列に

    // テーブル(fixed_header_table)の場合
    // cookieの中身を反映させる
    if ( table != null ) {
      for ( var i = 0; i < cookies.length; i++ ) {
        var elem = cookies[i].split("=")                     // ex.[' /admin/menus', '00111']
        if (elem[0].trim() == location.pathname) {
          let cookieSplit = decodeURI(elem[1]).split('');    // cookieを1つ1つ分解して配列に格納ex.['0','0','1','1','1']
          for ( let c = 0; c < cookieSplit.length; c++ ) {
            for ( var r = 0; r < table.rows.length; r++ ) {
              table.rows[r].cells[c].style.display = ( cookieSplit[c] == '1' ) ? '' :  'none'
            }
          }
        }
      }
    // div（data_area）の場合
    } else {
      var forms = document.querySelectorAll('.data_area form')
      var header_title = document.querySelectorAll('.header_title div')
      var cells = ''

      for ( var i = 0; i < cookies.length; i++ ) {
        var elem = cookies[i].split("=")                     // ex.[' /admin/menus', '00111']
        if (elem[0].trim() == location.pathname) {
          let cookieSplit = decodeURI(elem[1]).split('');    // cookieを1つ1つ分解して配列に格納ex.['0','0','1','1','1']
          for ( let c = 0; c < cookieSplit.length; c++ ) {
            for ( var r = 0; r < forms.length; r++ ) {
              cells = forms[r].querySelectorAll('.cell')
              header_title[c].style.display = ( cookieSplit[c] == '1' ) ? '' : 'none'   // 見出し
              cells[c].style.display        = ( cookieSplit[c] == '1' ) ? '' :  'none'  // データ部分
            }
          }
        }
      }
    }
  }
  //-------------------------------------------------------------
  // 親要素の切り替え
  // note:
  //   urlの@idを選択プルダウンの値と置き換えて実行
  // usage:
  //   data: {
  //     controller: 'index',
  //     action: 'change->index#change_parent',
  //     'index-url-value': "/master/houses/@id/areas"
  //   }
  //-------------------------------------------------------------
  static values = { url: String }
  change_parent(event) {
    // urlを取得し、@idをプルダウン値と置換
    var url = String(this.urlValue)
    url = url.replace('@id', this.element.value)
    // url遷移
    Turbo.visit(url, {action: 'replace'})
  }  
}
