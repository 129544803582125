import { Controller } from '@hotwired/stimulus'
//
// プルダウンの▼制御
//
//  note:
//    selectタグへのイベントで親divのclassにis-openを着脱する
//  usage:
//    <div class='pulldown'>
//      <%= f.select(:deposit_withdrawal, deposit_withdrawal_list, {}, {class: 'form-control', data: {controller: 'pulldown', action: 'click->pulldown#open blur->pulldown#close'} }) %>
//    </div>
//
export default class extends Controller {
  // プルダウン開く
  open() {
    this.element.parentNode.classList.toggle('is-open');
  }
  // プルダウン閉じる
  close() {
    this.element.parentNode.classList.remove('is-open');
  }
}