import Chart from 'chart.js/auto';
import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static values = {
    data: Object
  }

  initialize() {
    console.log(this.dataValue);

    const myChart = new Chart(this.element, {
        type: 'line',
        labels: this.labelsValue,
        data: {
            datasets: [{
                label: '室内温度',
                data: this.dataValue["temperature"],
                pointRadius: 0,
                borderColor: "rgb(255, 0, 0)",
                borderWidth: 2,
                yAxisID: 'y',
                xAxisID: 'x'
            }, {
                label: '室内湿度',
                data: this.dataValue["humidity"],
                pointRadius: 0,
                borderColor: "rgb(0,0,205)",
                borderWidth: 2,
                yAxisID: 'y',
                xAxisID: 'x'
            }, {
                label: 'CO2',
                data: this.dataValue["co2"],
                pointRadius: 0,
                borderColor: "rgb(50,205,50)",
                borderWidth: 2,
                yAxisID: 'y1',
                xAxisID: 'x'
            }, {
                label: '日射量',
                data: this.dataValue["solar"],
                pointRadius: 0,
                borderColor: "rgb(255,225,0)",
                borderWidth: 2,
                yAxisID: 'y1',
                xAxisID: 'x'
            }, {
                label: '水分率',
                data: this.dataValue["soil_moisture"],
                pointRadius: 0,
                borderColor: "rgb(30,144,255)",
                borderWidth: 2,
                yAxisID: 'y',
                xAxisID: 'x'
            }],
        },
        options: {
            scales: {
                y: {
                    type: 'linear',
                    beginAtZero: true,
                    position: 'left',    
                    min: 0,
                    max: 100,    
                        ticks:{
                            stepSize: 5
                        }
                },

                y1: {
                    type: 'linear',
                    beginAtZero: true,
                    position: 'right',
                    min: 0,
                    max: 1000,
                        ticks:{
                            stepSize:100
                        }
                },
                
                x: [{
                    type: 'time',
                    time: {
                        unit: 'hour',
                        unitStepSize: 2
                    }
                }]

            }
        }
    });
  }
}