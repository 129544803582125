import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  // 本関数は
  //  app/views/shared/_delete_dialogs.html.erb
  // にて使われます。

  static values = { id: Number }
  //-------------------------------------------------------------
  // ダイアログを開いたり閉じたり
  //   note:  indexの削除ボタン、閉じるボタン、×ボタンでダイアログの表示を切り替える
  //   usage:
  //     data: {controller: 'dialog',
  //     action:            'click->dialog#switch',
  //     'dialog-id-value': model.id}
  //-------------------------------------------------------------
  switch(event) {
    // オーバーレイの表示切替
    var overlay = document.querySelector('#overlay'+String(this.idValue));
    if (overlay.style.display != 'block') {
      overlay.style.display = 'block';
    } else {
      overlay.style.display = 'none';
    }
  }
}
