import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  // 本関数は
  //  app/views/transaction/harvests/show.html.erb
  //  app/views/shared/_header.html.erb
  // にて使われます。

  //-------------------------------------------------------------
  // ウインドウのonloadに相当
  // usage:   <%= tag.div class: "wrap-tab", data: { controller: 'harvest'} do %>
  //-------------------------------------------------------------
  initialize() {
    document.getElementById('tomorrow').classList.remove('active');
    document.getElementById('today_contents').classList.add('active');
    document.getElementById('today_ec').classList.add('active');
  }
  //  app/views/transaction/harvests/_tab.html.erb
  switch_tab() {
    document.getElementById('today').classList.toggle('active');
    document.getElementById('tomorrow').classList.toggle('active');
    document.getElementById('today_ec').classList.toggle('active');
    document.getElementById('tomorrow_ec').classList.toggle('active');
    document.getElementById('today_contents').classList.toggle('active');
    document.getElementById('tomorrow_contents').classList.toggle('active');
  }
}