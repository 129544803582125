import { Controller } from "@hotwired/stimulus"
import Uppy, { debugLogger } from "@uppy/core";
import Dashboard   from '@uppy/dashboard';
import StatusBar   from "@uppy/status-bar";        // ステータスバー
import Japanese    from "@uppy/locales/lib/ja_JP"; // 日本語
import English     from "@uppy/locales/lib/en_US"  // 英語
import Xhr         from "@uppy/xhr-upload";        // ローカルフォルダ
// import DragDrop from "@uppy/drag-drop";            // ドラッグ＆ドロップ
// import Tus      from "@uppy/tus";                  // TUSプロトコル
// import AwsS3    from "@uppy/aws-s3";               // S3
// import AwsS3M   from "@uppy/aws-s3-multipart";     // S3 Multipart

//
// ファイルアップロード
//  usage:
//    data-controller='uppy'             :固定
//    data-action='turbo:load@document->uppy#load' :画面ロード時(connect未使用)
//    data-uppy-uploader-value="xhr"     :アップロード方式(tus,s3など)
//    data-uppy-endpoint-value="/upload" :アップロード先エンドポイント
//    data-uppy-locale-value="ja"        :ロケール(ja, enなど)
//    
//    以下のようにdiv要素を配置
//    <div class='col-md-7' data-controller='uppy' data-action='turbo:load@document->uppy#load'
//        data-uppy-uploader-value='xhr' data-uppy-endpoint-value='/upload' data-uppy-locale-value='ja' >
//      <div data-uppy-target="area"></div>
//      <div data-uppy-target="status"></div>
//    </div>
//    <!-- Uppyで生成されたhiddenフィールドを格納するためのdiv -->
//    <div id="hiddens"></div>
//

export default class extends Controller {
  static values = {
    uploader: String, // アップロード形式(xhr,tus,s3)
    endpoint: String, // エンドポイント(アップロード先)
    locale:   String, // ロケール
    url:      String, // URL
  }
  static targets = ['area', 'status']

  //
  // Uppy接続
  //
  connect () {
    const UPLOADER = this.uploaderValue;
    const ENDPOINT = this.endpointValue;
    const LOCALE   = this.localeValue;
    // const COMPANION_URL = "http://companion.io";
    // const TUS_ENDPOINT  = "/tmp/uploads";

    if (this.element.tagName !== 'DIV') { return; }    // div要素以外は抜ける

    //------------------------------------
    // Uppyコア
    //   https://uppy.io/docs/uppy/
    //------------------------------------
    const uppy = new Uppy({
      logger:      debugLogger, // ロガー？
      autoProceed: true,        // 自動アップロード
    });

    //------------------------------------
    // ダッシュボード
    //------------------------------------    
    uppy.use(Dashboard, {
      debug:   true,   // 
      inline:  true,   // 
      target:  this.areaTarget,            // 
      locale:  this.switch_locale(LOCALE), // ロケール切替
      height:  200,    // 
      theme:   'dark', // 
      showProgressDetails:    true, // 
      replaceTargetContent:   true, // 
      animateOpenClose:       true, // 
      browserBackButtonClose: true, // 
      proudlyDisplayPoweredByUppy: false,  // 
      showRemoveButtonAfterComplete: true, // 
//      showLinkToFileUploadResult:    true,
//      note: 'Images and video only, 1–3 files, up to 1 MB',
//      metaFields: [
//          {id: 'caption', name: 'Caption', placeholder: 'describe what the image is about'}
//      ]
    });

    //------------------------------------
    // ステータスバー
    //------------------------------------
    uppy.use(StatusBar, {
      target: this.statusTarget
    });

    //------------------------------------
    // アップローダー毎処理
    //------------------------------------    
    switch (UPLOADER) {
      // case "tus":
      //   uppy.use(Tus,    {
      //     endpoint: TUS_ENDPOINT,
      //     limit:    6,
      //   });
      //   break;
      // case "s3":
      //   uppy.use(AwsS3,  { companionUrl: COMPANION_URL, limit: 6 });
      //   break;
      // case "s3-multipart":
      //   uppy.use(AwsS3M, { companionUrl: COMPANION_URL, limit: 6 });
      //   break;

      case "xhr":
        // https://uppy.io/docs/xhr-upload/
        uppy.use(Xhr, {
          endpoint:  ENDPOINT, // アップロード先
          limit:     6,        // ファイル最大数
          method:    'post',   // メソッド
          fieldName: 'attachments[]', // paramsでファイル群を送る名前
          bundle:    true,            // 一括ファイルアップロード
        });
        break;
      default:
    }

    // サーバーからCSRFトークンを取得し、アップロード時にセット
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    uppy.setMeta({ 'authenticity_token': csrfToken });
    // uppy インスタンスをブラウザのどこからでもアクセスできるように
    window.uppy = uppy;

    //------------------------------------
    // 添付ファイルアップロードイベント
    //------------------------------------
    uppy.on("complete", (result) => {
      // アップロード成功
      if (result.failed.length === 0) {
        // uppyのファイルid(配列)
        const fileIds = result.successful.map(fileInfo => fileInfo.id);
        // bodyからサーバ返却値取得
        result.successful[0].response['body'].forEach((file, index) => {
          // console.log(`成功ファイル ${index + 1}:`, file);
          // // bodyから値取得
          // console.log(fileIds[index]);
          // console.log(file.server_file_id);
          // console.log(file.original_filename);
          // フォームの項目に追加
          const hiddens = document.querySelector("#hiddens");
          const input   = document.createElement('input');
          input.type    = 'hidden';
          input.name    = 'attachments[]';
          // {uppy_file_id: 'uppyファイルID' ,server_file_id: 'サーバファイルID', original_filename: '元ファイル名'}
          input.value   = `{
            "uppy_file_id":       "${fileIds[index]}",
            "server_file_id":     "${file.server_file_id}",
            "original_file_name": "${file.original_filename}"
          }`;
          // hidden項目に追加
          hiddens.appendChild(input);
        });
      // アップロード失敗！
      } else {
        var message;
        result.failed.forEach((file, index) => {
          console.log(`失敗ファイル ${index + 1}:`, file);
          // 各ファイルに対する処理
          message += `${file.original_filename}、\n`;
        });
        alert(`以下のアップロードに失敗しました。\n${message}`);
      }
    });
    //------------------------------------
    // アップロードエラー処理
    //------------------------------------
    uppy.on('upload-error', (file, error) => {
      console.error(`ファイル ${file.name} のアップロード中にエラーが発生しました:${error}`);
      // エラーは
    });
    //------------------------------------
    // 添付ファイル削除イベント
    //------------------------------------
    uppy.on('file-removed', (file) => {
      var server_file_id;      // サーバファイルID
      // hidden項目を舐める
      const elements = document.querySelectorAll(`#hiddens input[type=hidden]`);
      elements.forEach((element) => {
        const value = element.value;  // valueにUppyファイルIDもサーバファイルIDもある
        // hidden項目でUppyファイルIDが一致したものを選定
        if (value.includes(file.id)) {
          const items = JSON.parse(value); // parseしてサーバファイルID取得
          server_file_id = items.server_file_id;
          // 要素を削除
          element.parentNode.removeChild(element);
        }
      });
      // サーバファイルIDを送ってサーバ上の一時ファイルを削除
      fetch('/upload/delete', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          authenticity_token: csrfToken,
          server_file_id:     server_file_id,
        }),
      })
      .then(response => {
        if (!response.ok) {
          var message = `  ステータス:${response.status}\n  エラー：${response.statusText}`;
          console.error(message);
          throw new Error(`サーバーエラーが発生しました。\n${message}`);
        }
      })
      .catch(error => {
        console.error(error.message);
        alert(error.message);
      });
    });
  }

  //
  // ロケール切替
  //
  switch_locale(locale) {
    switch (locale){
      case 'ja': return Japanese;
      default:   return English; 
    }
  }

  //
  // 添付ファイル削除
  //
  delete_attach(event) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;    // CSRFトークン取得
    const url = this.urlValue; // 引数からURL取得
    // 
    fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json', // 
      },
      body: JSON.stringify({
        'authenticity_token': csrfToken,    // CSRFトークン
      })
    })
    // 成功時の処理
    .then(response => {
      if (!response.ok) {
        var message = `  ステータス:${response.status}\n  エラー：${response.statusText}`;
        console.error(message);
        throw new Error(`サーバーエラーが発生しました。\n${message}`);
      }
     // 親要素を検索し削除
      var p = event.target.closest('.attachment');      
      if (p) { p.remove(); }
    })
    .catch(error => {
      console.error('エラーが発生しました:', error.message);
      alert('エラーが発生しました: ' + error.message);
    });
  } 

}
