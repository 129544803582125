import { Controller } from '@hotwired/stimulus'
import Choices from 'choices.js'

// 表示メッセージ
const MESSAGE = {
  en: {
    no_choices_text:  "No choices to choose from",
    loading_text:     "Loading...",
    item_select_text: "Please select",
    no_results_text:  "No result",
  },
  ja: {
    no_choices_text:  "選択肢がありません",
    loading_text:     "読み込み中...",
    item_select_text: "選択してください",
    no_results_text:  "見つかりません",
  },
  // 他言語もここに追加
}

export default class extends Controller {

  // 初期化
  initialize() {
    // 言語取得
    const translations = MESSAGE.ja
//    const translations = MESSAGE[lang] || MESSAGE.ja
    // 
    const choices = new Choices(this.element, {
      delimiter:        ',',
      searchEnabled:    true,      
      maxItemCount:     5,
      removeItemButton: true,
      noChoicesText:    translations.no_choices_text,  // 選択なし
      itemSelectText:   translations.item_select_text, // 
      noResultsText:    translations.no_results_text,  // 結果なし
      allowHTML:        true, // HTML を許可する
    });
  }


}