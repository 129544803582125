import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  // 本関数は
  //  app/views/shared/_index_dialogs.html.erb
  // にて使われます。

  //-------------------------------------------------------------
  // index列表示コントロールを開いたり閉じたり
  //   note:  index列表示コントロールダイアログ、閉じるボタン、×ボタンでダイアログの表示を切り替える
  //   usage:
  //     data: {controller: 'index_dialog',
  //     action:            'click->index_dialog#switch'}
  //-------------------------------------------------------------
  switch(event) {
    // オーバーレイの表示切替
    var overlay = document.querySelector('#overlay');
    var chk = ""

    if (overlay.style.display != 'block') {
      overlay.style.display = 'block'
      // ToDo
      // 1.ここでquerySelectorでthタグ取得
      var ths = document.querySelectorAll('th')

      // div（data_area）の場合
      if ( ths.length == 0 ) {
        ths = document.querySelectorAll('.header_title div')
      }

      // 先頭に２列表示のためのclass追加
      var chk_all = "<div class='block_parent'>"
      chk_all += "  <div class='block_2'>"
      
      // 2.checkboxを動的生成
      for ( let i = 0; i < ths.length-1; i++ ) {

        // ２列表示のclass(block_2)追加のための分岐
        if ( i == Math.round((ths.length-1) / 2) ) {
          chk +=  "  <div class='block_2'>"
        }

        // チェックボックス表示用の項目名作成
        var chkbox_text = ths[i].innerText
        if (chkbox_text == '') {
          chkbox_text = '関連データ'
        } else {
          // ▲▼を除外
          chkbox_text = chkbox_text.replace('▲', '')
          chkbox_text = chkbox_text.replace('▼', '')
        }

        chk += "<div class='toggle_column_checkbox'>"
        // チェックボックス作成
        chk += "  <div class='col-md-1 checkbox'>"
        chk += "    <input class='form-control' id='item_" + i + "' type='checkbox' data-controller='index_dialog'"
        chk += "     data-action='change->index_dialog#hide' data-index_dialog-id-value=" + i + ""
        chk += "     checked='checked'>"
        chk += "    <label for='item_" + i + "' class='checkbox_style'></label>"
        chk += "  </div>"
        chk += "  <label class='col-md-2 control-label'>" + chkbox_text + "</label>"
        chk += "</div>"
        
        // ２列表示のclass(/div)追加のための分岐
        if ( i == Math.round((ths.length-1)/2 - 1)) {
          chk +=   "  </div>"
        }
      }
    
      // chk と chk_all を合体させる
      chk_all = chk_all + chk

      chk_all += " </div>"
      chk_all += "</div>"
      
      // id=checkbox_listにcheckboxを追加
      document.getElementById('checkbox_list').innerHTML = chk_all

      // チェックボックスはこれ
      var arr_checkbox = document.querySelectorAll(`[id^='item_']`)
      
      var cookies = document.cookie.split(";");
      for ( var i = 0; i < cookies.length; i++ ) {
        var elem = cookies[i].split("=")
        if (elem[0].trim() == location.pathname) {
          let cookieSplit = decodeURI(elem[1]).split('');        // 1つ1つ分解して配列に格納
          for ( let j = 0; j < arr_checkbox.length; j++ ) {  // item_nとarray[n]にセット .checked = true
            (cookieSplit[j] == '1') ? arr_checkbox[j].checked=true : arr_checkbox[j].checked=false
          }
        }
      }

    } else {
      overlay.style.display = ''
    }
  }

  //-------------------------------------------------------------
  // 保存処理
  //   note:  index列表示のon/offをcookieに保存
  //-------------------------------------------------------------
  save() {
    // チェックボックスをなめる(id=item_n)
    var arr_checkbox = document.querySelectorAll(`[id^='item_']`)
    var chk_status = ''

    // 要素のIDとチェック状態をcookieに保持
    for ( let i = 0; i < arr_checkbox.length; i++ ) {
      (arr_checkbox[i].checked) ? chk_status += '1' : chk_status += '0'
    }
    document.cookie = location.pathname + '=' + chk_status + '; expires=Mon, 31 Aug 2220 00:00:00 GMT; Path=/'
    
    // ダイアログボックスを閉じる
    this.switch()
  }

  static values = { id: Number }
  //-------------------------------------------------------------
  // テーブル列非表示　//
  //-------------------------------------------------------------
  hide() {
    var id = String(this.idValue)
    var table  = document.getElementById("table")
    var chkbox = document.getElementById("item_" + id)

    // テーブル(fixed_header_table)の場合
    if ( table != null ) {
      for ( var r = 0; r < table.rows.length; ++r ) {
        table.rows[r].cells[id].style.display = (chkbox.checked) ? '' : 'none'
      }
    // div(data_area)の場合
    } else {
      var forms = document.querySelectorAll('.data_area form')
      var header_title = document.querySelectorAll('.header_title div') // 見出し
      var cells = ''
      for ( var r = 0; r < forms.length; ++r ) {
        cells = forms[r].querySelectorAll('.cell')
        header_title[id].style.display = (chkbox.checked) ? '' : 'none' // 見出し
        cells[id].style.display = (chkbox.checked) ? '' : 'none'        // データ部分
      }
    }
  }
}