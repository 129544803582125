import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  //-------------------------------------------------------------
  // アコーディオンコンテンツ
  //   note:
  //     コンテンツをアコーディオンメニューで表示する
  //   usage:
  //     <div class='accordion_group' data-controller='accordion'>
  // -----------------------------------------------------------
  //  《 アコーディオンコンテンツの作り方 》
  //    1.アコーディオンメニュー・アコーディオンコンテンツ全体を<div class='accordion_group' data-controller='accordion'></div>で囲む
  //    2.アコーディオンメニュー ＋ アコーディオンコンテンツを<div class='accordion'></div>で囲む
  //    3.アコーディオンメニューは、  <button class='accordion_menu'>アコーディオンメニュー1</button>
  //    4.アコーディオンコンテンツは、<div class='accordion_contents'><p>アコーディオンコンテンツ</p></div>
  //   （例）
  //      <div class='accordion_group' data-controller='accordion'>
  //        <div class='accordion'>
  //          <button class='accordion_menu'>アコーディオンメニュー1</button>
  //          <div class='accordion_contents'>
  //            <p>アコーディオンコンテンツ１１１１１</p>
  //          </div>
  //        </div>
  //        <div class='accordion'>
  //          <button class='accordion_menu'>アコーディオンメニュー2</button>
  //          <div class='accordion_contents'>
  //            <p>アコーディオンコンテンツ２２２２２</p>
  //          </div>
  //        </div>
  //      </div>
  //-------------------------------------------------------------
  connect () {
    var acc = document.getElementsByClassName('accordion_menu');
    for (var i = 0; i < acc.length; i++) {
      acc[i].onclick = function() {
        this.classList.toggle('active');
        this.nextElementSibling.classList.toggle('show');
      }
    }
  }
}